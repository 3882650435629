<template>
  <div class="container">
    <form @submit.prevent="register" v-if="!showConfirm" id="registerForm">
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input class="form-control"
                 type="email"
                 v-model="email"
                 placeholder="Email address..."
          />
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input
              class="form-control"
              type="password"
              v-model="password"
              placeholder="password..."
          />
        </div>
      </div>
      <div v-if="errorMessage">
        <p class="errorMessage">
          {{ errorMessage }}
        </p>
      </div>
      <div>
        <button class="button">Register</button>
      </div>
    </form>

    <form v-if="showConfirm" @submit.prevent="confirm">
      <h4>Confirm registration</h4>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input class="form-control"
                 type="text"
                 v-model="code"
                 placeholder="confirmation code..."
          />
        </div>
      </div>

      <div v-if="errorMessage">
        <p class="errorMessage">
          {{ errorMessage }}
        </p>
      </div>

      <div v-if="!confirmSubmitted">
        <button class="button">Register</button>
      </div>

      <div class="spinner-border" role="status" v-if="confirmSubmitted" style="margin-top: 40px">
        <span class="visually-hidden">Loading...</span>
      </div>
    </form>
  </div>
</template>

<script>

import {AttiniAuth} from "@/AttiniAuth";

export default {
  name: "Register",
  data() {
    return {
      email: '',
      password: '',
      code: '',
      showConfirm: false,
      confirmSubmitted: false,
      errorMessage: '',
      wrongConfirmationCode: false
    };
  },
  methods: {
    async register() {
      AttiniAuth.signUp(this.email, this.password)
          .then(() => {
            this.errorMessage = '';
            this.showConfirm = true;
          })
          .catch(reason => {
            if (reason.code === "InvalidPasswordException") {
              this.errorMessage = reason.message;
            }else {
              this.errorMessage = "Something went wrong, please wait a bit and try again. If the problem persist please contact Attini support.";
            }
          });
    },
    async confirm() {
      this.confirmSubmitted = true;
      try {
        await AttiniAuth.confirmSignUp(this.email, this.code)
            .then(() => AttiniAuth.login(this.email, this.password))
            .then(() => this.$router.push("/create-organization"))
            .catch(reason => {
              this.confirmSubmitted = false;
              if (reason.code === "CodeMismatchException") {
                this.errorMessage = "Wrong confirmation code.";
              } else {
                this.errorMessage = "Something went wrong, please wait a bit and try again. If the problem persist please contact Attini support.";
              }
            });
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
  }
}
</script>

<style scoped>
#registerForm > * {
  margin: 20px 0;
}

</style>